import React from "react";
import config from "config";

const routeData = [
	{
		sitemapHeading: "Freedom Brokers",
		route: "/",
		component: require("siteViews/Landing").default,
		pageDataCy: "page:landing",
		landingImage: true,
		siteClassName: "landing",
		headerImages: {
			desktopImage: require("siteAssets/images/home-background-colour.webp"),
			mobileImage: require("siteAssets/images/home-background-mobile-colour.webp"),
			desktopContent: {
				strapLine: "For great value insurance",
				links: [
					{ id: "dtp-van", label: "Get a VAN quote", href: config.links.quoteStart.van, className: "" },
					{ id: "dtp-car", label: "Get a CAR quote", href: config.links.quoteStart.car, className: "" },
					{ id: "dtp-gap", label: "Get a GAP quote", href: config.links.quoteStart.gap, className: "" },
				]
			},
			mobileContent: {
				links: [
					{ id: "mbl-van", label: "Get a VAN quote", href: config.links.quoteStart.van, className: "btn-sm" },
					{ id: "mbl-car", label: "Get a CAR quote", href: config.links.quoteStart.car, className: "btn-sm" },
					{ id: "mbl-gap", label: "Get a GAP quote", href: config.links.quoteStart.gap, className: "btn-sm" },
				]
			},
		}
	},
	
	config.isDev
		? {
				sitemapHeading: "Freedom To Drive",
				route: "/test",
				component: require("siteViews/Test").default,
			pageDataCy: "page:test",
		  }
		: undefined,

	{
		sitemapHeading: "About Us",
		route: "/about-us",
		component: require("siteViews/AboutUs").default,
		pageDataCy: "page:about-us",
	},

	{
		sitemapHeading: "Terms of business",
		route: "/terms-of-business",
		component: require("siteViews/TermsOfBusiness").default,
		pageDataCy: "page:terms-of-business",
	},

	{
		sitemapHeading: "My Account",
		route: "/my-account",
		component: require("siteViews/MyAccount").default,
		pageDataCy: "page:my-account",
	},

	{
		sitemapHeading: "Contact Us",
		route: "/contact-us",
		component: require("siteViews/ContactUs").default,
		pageDataCy: "page:contact-us",
	},
	
	{
		sitemapHeading: "Customer Support",
		route: "/customer-support",
		component: require("siteViews/CustomerSupport").default,
		pageDataCy: "page:customer-support",
	},
	
	{
		sitemapHeading: "Terms of use",
		route: "/terms-of-use",
		component: require("siteViews/TermsOfUse").default,
		pageDataCy: "page:terms-of-use",
	},

	{
		sitemapHeading: "Privacy Policy",
		route: "/privacy-policy",
		component: require("siteViews/PrivacyPolicy").default,
		pageDataCy: "page:privacy-policy",
	},
	
	{
		sitemapHeading: "Cookie Policy",
		route: "/cookie-policy",
		component: require("siteViews/CookiePolicy").default,
		pageDataCy: "page:cookie-policy",
	},
	
	config.showFaqs ? {
		sitemapHeading: "FAQs",
		route: "/faqs",
		component: require("siteViews/Faqs").default,
		pageDataCy: "page:faqs",
	} : undefined,
	
	{
		sitemapHeading: "Make a claim",
		route: "/make-a-claim",
		component: require("siteViews/MakeAClaim").default,
		pageDataCy: "page:make-a-claim",
	},
	
	{
		sitemapHeading: "Green card",
		route: "/green-card",
		component: require("siteViews/GreenCard").default,
		pageDataCy: "page:green-card",
	},
	
	{
		sitemapHeading: "Make a complaint",
		route: "/make-a-complaint",
		component: require("siteViews/MakeAComplaint").default,
		pageDataCy: "page:make-a-complaint",
	},
	
	{
		route: "/payments",
		component: require("siteViews/Payments").default,
		exact: false,
		pageDataCy: "page:payments",
	},

	/*
	{
		sitemapHeading: "Work With Us",
		route: "/work-with-us",
		component: require("siteViews/WorkWithUs").default,
	},

	{
		sitemapHeading: "Careers",
		externalLink: "https://freedombrokers.livevacancies.co.uk",
	},
	*/

	/* POLICY TYPES */
	{
		sitemapHeading: "Motor Insurance",
		route: "/motor-insurance",
		component: require("siteViews/MotorInsurance").default,
		pageDataCy: "page:motor-insurance",
		landingImage: true,
		siteClassName: "landing",
		headerImages: {
			desktopImage: require("siteAssets/images/home-background-colour.webp"),
			mobileImage: require("siteAssets/images/home-background-mobile-colour.webp"),
			desktopContent: {
				strapLine: "For great value motor insurance",
				links: [
					{ id: "dtp-van", label: "Get a VAN quote", href: config.links.quoteStart.van, className: "" },
					{ id: "dtp-car", label: "Get a CAR quote", href: config.links.quoteStart.car, className: "" },
				]
			},
			mobileContent: {
				links: [
					{ id: "mbl-van", label: "Get a VAN quote", href: config.links.quoteStart.van, className: "me-2 mb-2" },
					{ id: "mbl-car", label: "Get a CAR quote", href: config.links.quoteStart.car, className: "me-2 mb-2" },
				]
			},
		}
	},
	
	{
		sitemapHeading: "Freedom To Drive",
		route: "/telematics",
		component: require("siteViews/Telematics").default,
		pageDataCy: "page:telematics",
		landingImage: true,
		siteClassName: "landing",
		headerImages: {
			desktopImage: require("siteAssets/images/car-background.webp"),
			mobileImage: require("siteAssets/images/car-background.webp"),
			desktopContent: {
				strapLine: (
					<span>
						For great value car insurance
						<small className="d-block ms-3 fs-6 fst-italic">get a 'black box' telematics quote today</small>
					</span>
				),
				links: [
					{ id: "dtp-car", label: "Get a quote", href: config.links.quoteStart.telematics, className: "" },
				]
			},
			mobileContent: {
				links: [
					{ id: "mbl-car", label: "Get a quote", href: config.links.quoteStart.telematics, className: "me-2" },
				]
			},
		}
	},

	{
		sitemapHeading: "GAP Insurance",
		route: "/gap-insurance",
		component: require("siteViews/GapInsurance").default,
		pageDataCy: "page:gap-insurance",
	},
	// #3487 
	// {
	// 	sitemapHeading: "Commercial Insurance",
	// 	route: "/commercial-insurance",
	// 	component: require("siteViews/CommercialInsurance").default,
	// 	pageDataCy: "page:commercial-insurance",
	// 	landingImage: true,
	// 	siteClassName: "landing",
	// 	headerImages: {
	// 		desktopImage: require("siteAssets/images/commercial-background.webp"),
	// 		mobileImage: require("siteAssets/images/commercial-background.webp"),
	// 		desktopContent: {
	// 			strapLine: "For great value insurance",
	// 			links: [
	// 				{ id: "dtp-call", label: `Call us on ${config.phoneNumbers.commercial}`, phoneNumber: config.phoneNumbers.commercial, className: "" },
	// 			]
	// 		},
	// 		mobileContent: {
	// 			links: [
	// 				{ id: "mbl-call", label: `Call us on ${config.phoneNumbers.commercial}`, phoneNumber: config.phoneNumbers.commercial, className: "" },
	// 			]
	// 		},
	// 	}
	// },

	/*
	{
		sitemapHeading: "Car Insurance",
		route: "/car-insurance",
		component: require("siteViews/CarInsurance").default,
	},
	*/
	/*
	{
		sitemapHeading: "Van Insurance",
		route: "/van-insurance",
		component: require("siteViews/VanInsurance").default,
	},
	*/
	/*
	{
		sitemapHeading: "Home Insurance",
		route: "/home-insurance",
		component: require("siteViews/HomeInsurance").default,
	},
	*/
	/*
	{
		sitemapHeading: "Pet Insurance",
		route: "/pet-insurance",
		component: require("siteViews/PetInsurance").default,
	},
	*/
		
	/* OPEX TYPES */

	{
		sitemapHeading: "Optional Cover",
		route: "/optional-cover",
		component: require("siteViews/OptionalCover").default,
		pageDataCy: "page:optional-cover",
	},
	{
		sitemapHeading: "Breakdown Cover",
		route: "/breakdown-cover",
		component: require("siteViews/BreakdownCover").default,
		pageDataCy: "page:breakdown-cover",
	},
	{
		sitemapHeading: "Excess Protection",
		route: "/excess-protection",
		component: require("siteViews/ExcessProtection").default,
		pageDataCy: "page:excess-protection",
	},
	{
		sitemapHeading: "Goods in Transit",
		route: "/goods-in-transit",
		component: require("siteViews/GoodsInTransit").default,
		pageDataCy: "page:goods-in-transit",
	},
	{
		sitemapHeading: "Key Cover",
		route: "/key-cover",
		component: require("siteViews/KeyCover").default,
		pageDataCy: "page:key-cover",
	},
	{
		sitemapHeading: "Legal Expenses",
		route: "/legal-expenses",
		component: require("siteViews/LegalExpenses").default,
		pageDataCy: "page:legal-expenses",
	},
	{
		sitemapHeading: "Motor Prosecution Defence",
		route: "/motor-prosecution-defence",
		component: require("siteViews/MotorProsecutionDefence").default,
		pageDataCy: "page:motor-prosecution-defence",
	},
	{
		sitemapHeading: "Replacement Hire Vehicle",
		route: "/replacement-hire-vehicle",
		component: require("siteViews/ReplacementHireVehicle").default,
		pageDataCy: "page:replacement-hire-vehicle",
	},
	{
		sitemapHeading: "Tools in Transit",
		route: "/tools-in-transit",
		component: require("siteViews/ToolsInTransit").default,
		pageDataCy: "page:tools-in-transit",
	},

	{
		sitemapHeading: "Scratch, Dent & Alloy Protection",
		route: "/scratch-dent-alloy",
		component: require("siteViews/ScratchDentAlloy").default,
		pageDataCy: "page:scratch-dent-alloy",
	},
	
].filter((x) => x !== undefined);

export default routeData;
